import Vue from 'vue';
import {
  computed,
  ref,
  watch,
  SetupContext,
  Ref,
  shallowRef,
} from '@vue/composition-api';
import { QueryStatusErrorFromQuery } from '@ax/cache-and-dedupe-vue/core';
import VueRouter, { Route } from 'vue-router';
import { TableHeader } from '@ax/cosmos/components/Table/table.models';
import {
  showStandardHttpErrorNotification,
  showErrorNotification as showErrorToast,
  showSuccessNotification,
} from '@ax/notifications';

import { useCurrentOrgId } from '@ax/vue-utils/router';
import { SeverityFilters } from '@ax/features-software/models';
import { format } from 'date-fns';
import { getOSNameVersion } from '@ax/data-services-devices/business-logic';
import { useExportCsvUrl } from '@ax/data-services-devices/compositions/use-export-csv-url';
import { DataTableHeader } from 'vuetify';
import { isNonNullable } from '@ax/function-utils/nullable';
import { ZoneDeviceTableRow } from '@ax/features-devices/models/device-table';
import { consoleI18n } from '@ax/console-i18n';
import { Device } from '@/models/devices';
import {
  postDevicesBatchQueue,
  removeDevice,
  batchProcessTags,
  useGetDevicesQuery,
  GetDevices,
  runGetDevices,
} from '@/services/devices.service';
import { DeviceCommands } from '@/types/device-commands';
import { FilterOption } from '@/types/select-item';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateStorageConfig } from '@/utils/storage';
import { COMPLIANT, PENDING, CONNECTED } from '@/utils/devices';
import { DEVICE_GROUPS_ASSIGN } from '@/store/actions/device-groups';
import { DataTableState, DataTablePageState } from '@/utils/data-table-state';
import { OrgIdNumber } from '@/models/org-id';
import { DATE_FORMAT } from '@/utils/date-time';
import { snakeToReadable } from '@/utils/display-text';
import { showStandardCSVExportErrorNotification } from '@/utils/util';
import { generateAndDownloadCSV } from '@/utils/csv-converter';
import { deviceStatusText } from './useDevice';
import { getCurrentUser } from './useUser';
import { useOses } from './useOses';
import {
  useDeviceGroups,
  assignDeviceGroup,
  DeviceGroupByIdMap,
} from './useDeviceGroups';

export interface DevicesQueryState extends DataTablePageState {
  internal: 1;
  commands: number;
  include_details: 0 | 1;
  include_next_patch_time: 0 | 1;
  include_server_events: 0 | 1;
  // include_policy_status: 0 | 1;
  connected: number | undefined;
  uptodate: number | undefined;
  compliant: number | undefined;
  pending: number | undefined;
  excluded_from_reports: boolean | number | undefined;
  needs_attention: boolean | number | undefined;
  is_compatible: boolean | number | undefined;
  recently_added: boolean | number | undefined;
  package_status: string | undefined;
  package_version_ids: string[];
  device_name: string[];
  tag: string[];
  last_logged_in_user: string[];
  ip_address: string[];
  serial_number: string[];
  os: number[];
  severity: string[];
  vulnerability: string[];
  policy: string[];
  group: string[];
  columns: Array<keyof ZoneDeviceTableRow>;
}

export const devicesBaseQueryState: Partial<DevicesQueryState> = {
  package_version_ids: [],
  package_status: undefined,
  connected: undefined,
  uptodate: undefined,
  compliant: undefined,
  pending: undefined,
  excluded_from_reports: undefined,
  needs_attention: undefined,
  recently_added: undefined,
  device_name: [],
  tag: [],
  last_logged_in_user: [],
  ip_address: [],
  serial_number: [],
  os: [],
  severity: [],
  vulnerability: [],
  policy: [],
  group: [],
};

export type ComplianceRadioGroupValue =
  | 'up_to_date'
  | 'compliant'
  | 'failed'
  | 'scheduled'
  | undefined;

export function getNextQueryStateFromComplianceRadioValue(
  radioValue: ComplianceRadioGroupValue,
  currentState: DevicesQueryState,
): Partial<DevicesQueryState> {
  switch (radioValue) {
    case 'up_to_date':
      return {
        uptodate: 1,
        compliant: 1,
        pending: 0,
        excluded_from_reports:
          currentState.excluded_from_reports === true
            ? currentState.excluded_from_reports
            : 0,
        needs_attention:
          currentState.needs_attention === true
            ? currentState.needs_attention
            : 0,
      };
    case 'compliant':
      return {
        uptodate: undefined,
        compliant: 1,
        pending: 0,
        excluded_from_reports:
          currentState.excluded_from_reports === true
            ? currentState.excluded_from_reports
            : undefined,
        needs_attention:
          currentState.needs_attention === true
            ? currentState.needs_attention
            : undefined,
      };
    case 'failed':
      return {
        uptodate: undefined,
        compliant: 0,
        pending: undefined,
        excluded_from_reports:
          currentState.excluded_from_reports === true
            ? currentState.excluded_from_reports
            : undefined,
        needs_attention:
          currentState.needs_attention === true
            ? currentState.needs_attention
            : undefined,
      };
    case 'scheduled':
      return {
        uptodate: undefined,
        compliant: 2,
        pending: 1,
        excluded_from_reports:
          currentState.excluded_from_reports === true
            ? currentState.excluded_from_reports
            : undefined,
        needs_attention:
          currentState.needs_attention === true
            ? currentState.needs_attention
            : undefined,
      };
    default:
      throw new Error(
        'Undexpected value was provided by compliance radio group selection',
      );
  }
}

export function getComplianceRadioValueFromQueryState(
  currentState: DevicesQueryState,
): ComplianceRadioGroupValue {
  if (currentState.uptodate === 1) {
    return 'up_to_date';
  }
  if (currentState.compliant === 1) {
    return 'compliant';
  }
  if (currentState.compliant === 0) {
    return 'failed';
  }
  if (currentState.compliant === 2) {
    return 'scheduled';
  }
  return undefined;
}

export function getNextQueryStateFromExclusionCheckboxValue(
  checkboxValue: boolean,
  currentState: DevicesQueryState,
): Partial<DevicesQueryState> {
  if (checkboxValue === true) {
    return { excluded_from_reports: true };
  }
  if (currentState.uptodate === 1) {
    return { excluded_from_reports: 0 };
  }
  return { excluded_from_reports: undefined };
}

function baseDevicesQueryStateConfiguration(orgId: Ref<number>) {
  return {
    o: { defaultValue: orgId.value },
    limit: {
      defaultValue: DEFAULT_TABLE_PAGINATION,
      storeInBrowser: true,
      apiKeyTranslation: 'l',
    },
    sort_dir: {
      defaultValue: 'asc',
      legacyUiKeyTranslation: 'sort',
      apiKeyTranslation: 'sortDir',
    },
    sort_by: {
      defaultValue: 'display_name',
      apiKeyTranslation: 'sortColumns',
    },
    page: {
      defaultValue: 1,
      legacyUiValueTranslations: new Map().set(0, 1),
      apiKeyTranslation: 'p',
    },
  };
}

function showErrorNotification(
  newError?: QueryStatusErrorFromQuery<GetDevices>,
) {
  if (newError === undefined) {
    return;
  }
  if (newError.type === 'ApiClientError') {
    return showStandardHttpErrorNotification(newError.error);
  }
  showErrorToast();
}

function sharedUseDevices(queryString: Ref<string | undefined>) {
  const query = computed(() =>
    queryString.value !== undefined
      ? new GetDevices({ query: queryString.value })
      : undefined,
  );

  const { data, isLoading: loading, error } = useGetDevicesQuery(query);

  watch(error, showErrorNotification);

  const devices = computed(() => (data.value ? data.value.results : []));

  const totalDevices = computed(() => (data.value ? data.value.size : 0));

  const hasNoDevices = computed(() => devices.value.length === 0);

  function getDevices() {
    const currentQuery = query.value;
    if (currentQuery) {
      runGetDevices(currentQuery);
    }
  }

  return {
    getDevices,
    loading,
    devices,
    totalDevices,
    hasNoDevices,
    exportCsvUrl: useExportCsvUrl(queryString),
  };
}

/**
 * Reboots the provided devices (or devices with the provided ids).
 * @param toReboot The devices to reboot. Take in either an array of devices or device ids.
 * @param {number} orgId the org id
 */
export function rebootOneOrMoreDevices(
  toReboot: Device[] | number[],
  orgId: number,
) {
  return postDevicesBatchQueue(
    // If a list of devices were provided, grab just the ids; if a list of ids were provided, use that list as-is.
    toReboot.map((d: Device | number) => (typeof d !== 'number' ? d.id : d)),
    DeviceCommands.reboot,
    orgId,
  )
    .then(() => {
      showSuccessNotification(
        consoleI18n.tc('devices.reboot:notification.success', toReboot.length),
      );
    })
    .catch(showStandardHttpErrorNotification);
}

/**
 * @param context The context of the application/component.
 * @param orgId Optional. The current organization id. By default is obtained using the useCurrentOrgId composition.
 * @param route Optional. The route. By default is grabbed by the context.
 * @param router Optional. The router. By default is grabbed by the context.
 * @returns The generated useDevice composition.
 */
export function useDevices(
  context: SetupContext,
  orgId: Ref<number> = useCurrentOrgId(),
  route?: Route,
  router?: VueRouter,
) {
  const orgIdRef = computed(
    () => orgId.value || Number(context.root.$store.state.route.query.o),
  );

  const selectedDevices = shallowRef<Device[]>([]);
  const { oses, totalOses } = useOses(orgIdRef);

  const queryString = shallowRef<string>();

  const {
    devices,
    totalDevices,
    hasNoDevices,
    loading,
    getDevices,
    exportCsvUrl,
  } = sharedUseDevices(queryString);

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-devices-prefs',
    store: localStorage,
  };

  const baseConfiguration = baseDevicesQueryStateConfiguration(orgIdRef);
  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<ZoneDeviceTableRow, DevicesQueryState>(
      route || context.root.$route,
      router || context.root.$router,
      {
        ...baseConfiguration,
        sort_by: {
          ...baseConfiguration.sort_by,
          legacyUiValueTranslations: new Map().set('os', 'os_version'),
          legacyUiKeyTranslation: 'order_by',
          // the below translation is handled in the onBeforeApiUrlGeneration function below
          // apiValueTranslations: new Map().set('disconnected_for', 'last_disconnect_time'),
        },
        columns: {
          defaultValue: [
            'status',
            'os_family',
            'display_name',
            'is_compatible',
            'last_disconnect_time',
            'server_group_id',
            'tags',
            'ip_addrs',
            'os_version',
            'pending_patches',
            'agent_version',
            'actions',
          ],
          storeInBrowser: true,
          excludeFromApiUrl: true,
        },
        filter_panel_open: {
          defaultValue: true,
          storeInBrowser: true,
          excludeFromBrowserUrl: true,
          excludeFromApiUrl: true,
        },
        internal: {
          defaultValue: 1,
          excludeFromBrowserUrl: true,
        },
        include_details: {
          defaultValue: 0,
          excludeFromBrowserUrl: true,
        },
        include_next_patch_time: {
          defaultValue: 0,
          excludeFromBrowserUrl: true,
        },
        include_server_events: {
          defaultValue: 0,
          excludeFromBrowserUrl: true,
        },
        /*
          include_policy_status: {
          defaultValue: 0,
          excludeFromBrowserUrl: true,
        }, */
        q: {
          defaultValue: [],
          apiKeyTranslation: 'filter',
        },
        package_version_ids: {
          defaultValue: [],
        },
        package_status: {
          defaultValue: undefined,
        },
        connected: {
          defaultValue: undefined,
        },
        uptodate: {
          defaultValue: undefined,
          excludeFromApiUrl: true,
        },
        compliant: {
          defaultValue: undefined,
        },
        pending: {
          defaultValue: undefined,
          excludeFromBrowserUrl: true,
        },
        excluded_from_reports: {
          defaultValue: undefined,
          legacyUiValueTranslations: new Map().set(1, true),
          legacyUiKeyTranslation: 'exception',
          apiKeyTranslation: 'exception',
          apiValueTranslations: new Map().set(true, 1),
        },
        needs_attention: {
          defaultValue: undefined,
          legacyUiKeyTranslation: 'needsAttention',
          apiKeyTranslation: 'needsAttention',
          legacyUiValueTranslations: new Map().set(1, true),
          apiValueTranslations: new Map().set(true, 1).set(false, undefined),
        },
        is_compatible: {
          defaultValue: undefined,
          apiKeyTranslation: 'is_compatible',
          isApiFilter: true,
          apiValueTranslations: new Map().set(true, 0).set(false, undefined),
        },
        recently_added: {
          defaultValue: undefined,
          legacyUiKeyTranslation: 'recentlyAdded',
          legacyUiValueTranslations: new Map().set(1, true),
          excludeFromApiUrl: true,
        },
        device_name: {
          defaultValue: [],
          isApiFilter: true,
        },
        tag: {
          defaultValue: [],
          legacyUiKeyTranslation: 'tags',
          isApiFilter: true,
        },
        last_logged_in_user: {
          defaultValue: [],
          isApiFilter: true,
        },
        ip_address: {
          defaultValue: [],
          isApiFilter: true,
          apiKeyTranslation: 'ip',
        },
        serial_number: {
          defaultValue: [],
          isApiFilter: true,
        },
        os: {
          defaultValue: [],
          legacyUiKeyTranslation: 'oses',
          apiKeyTranslation: 'os_id',
          isApiFilter: true,
          dataFilterMaxLength: totalOses,
        },
        severity: {
          defaultValue: [],
          isApiFilter: true,
          dataFilterMaxLength: ref(SeverityFilters.length),
        },
        vulnerability: {
          defaultValue: [],
          isApiFilter: true,
          apiKeyTranslation: 'cves',
        },
        policy: {
          defaultValue: [],
          legacyUiKeyTranslation: 'policyId',
          apiKeyTranslation: 'policyId',
        },
        group: {
          defaultValue: [],
          legacyUiKeyTranslation: 'groupId',
          apiKeyTranslation: 'groupId',
        },
      },
      {
        storageConfig,
        /**
         * This sometimes gets called in short succession so debouncing it
         */
        callback: ({ apiQuery }) => {
          queryString.value = apiQuery;
        },
        onBeforeUiUrlGeneration: (state) => {
          if (state.uptodate === 1) {
            return {
              ...state,
              compliant: undefined,
              pending: undefined,
              excluded_from_reports: undefined,
              needs_attention: undefined,
            };
          }
          return state;
        },
        onBeforeApiUrlGeneration: (state) => {
          const next = state;
          if (Number.isInteger(next.compliant)) {
            if (next.compliant === 1) {
              next.pending = 0;
            }
            if (next.compliant === 2) {
              next.compliant = undefined;
              next.pending = 1;
            }
          }

          if (next.sort_by === 'disconnected_for') {
            next.sort_by = ['last_disconnect_time'];
          } else if (next.sort_by === 'os_version') {
            next.sort_by = ['os_name', 'os_version'];
          } else if (next.sort_by === 'server_group_id') {
            next.sort_by = ['server_group_name'];
          } else {
            next.sort_by = [next.sort_by];
          }

          return {
            ...next,
            compliant: next.compliant === 2 ? undefined : next.compliant,
            q:
              next.recently_added === true
                ? [...next.q, 'Recently Added']
                : next.q,
          };
        },
      },
    );

  function scanDevices(toScan: Device[]): void {
    postDevicesBatchQueue(
      toScan.map(({ id }) => id!),
      DeviceCommands.getOS,
      Number(orgIdRef.value),
    )
      .then(() => {
        selectedDevices.value = [];
        Vue.notify({
          group: 'snackbar',
          text: `Request to scan ${
            toScan.length > 1 ? 'devices' : 'device'
          } sent.`,
          type: '{ "card": "success" }',
        });
      })
      .catch(showStandardHttpErrorNotification);
  }

  function removeDevices(toRemove: Device[]): void {
    const removeDevicePromises = toRemove.map(({ id }) =>
      removeDevice(Number(orgIdRef.value), id!),
    );

    Promise.all(removeDevicePromises)
      .then(() => {
        selectedDevices.value = [];
        Vue.notify({
          group: 'snackbar',
          text: `${toRemove.length > 1 ? 'Devices' : 'Device'} removed.`,
          type: '{ "card": "success" }',
        });
      })
      .then(getDevices, showStandardHttpErrorNotification);
  }

  function assignDevicesToGroup(toAssign: Device[], selectedGroup: number) {
    assignDeviceGroup(context, selectedGroup, toAssign)
      .then(() => {
        selectedDevices.value = [];
        Vue.notify({
          group: 'snackbar',
          text: `Success! ${
            toAssign.length > 1 ? 'Devices' : 'Device'
          } moved to group.`,
          type: '{ "card": "success" }',
        });
      })
      .then(getDevices, showStandardHttpErrorNotification);
  }

  function batchEditTags(
    action: 'apply' | 'remove',
    devicesToEdit: Device[],
    tags: string[],
  ) {
    const deviceIds = devicesToEdit
      .filter((device): boolean => !!(device && device.id))
      .map((device): number => device.id!);

    return batchProcessTags(action, orgIdRef.value, tags, deviceIds)
      .then(() => {
        selectedDevices.value = [];
        Vue.notify({
          group: 'snackbar',
          text: `${tags.length} ${
            tags.length > 1 ? ' tags are' : ' tag is'
          } being ${action === 'remove' ? 'removed from' : 'applied to'} ${
            devicesToEdit.length
          } ${
            devicesToEdit.length > 1 ? ' devices' : ' device'
          }. It may take a few minutes to complete.`,
          type: '{ "card": "success" }',
        });
        /**
         * This could potentially result in new tags being created, as such
         * we should refresh the `User` so that the tags it has are up to date.
         */
        if (action === 'apply') {
          getCurrentUser(`${orgIdRef.value}`, true);
        }
      })
      .catch(showStandardHttpErrorNotification);
  }

  watch(
    orgIdRef,
    (next, prev) => {
      if (prev && next !== prev) {
        updateQueryState({ ...devicesBaseQueryState, q: [], o: Number(next) });
      }
    },
    {
      immediate: true,
    },
  );

  function rebootDevices(toReboot: Device[] | number[]) {
    rebootOneOrMoreDevices(toReboot, Number(orgIdRef.value)).then(() => {
      selectedDevices.value = [];
    });
  }

  return {
    queryString,
    orgIdRef,
    loading,
    devices,
    selectedDevices,
    totalDevices,
    hasNoDevices,
    exportCsvUrl,
    oses,
    totalOses,
    severityFilters: SeverityFilters,
    tableState,
    queryState,
    updateQueryState,
    scanDevices,
    rebootDevices,
    removeDevices,
    assignDevicesToGroup,
    batchEditTags,
  };
}

/**
 * Use to format the `is_compatible` column in a string for inclusion in a csv.
 * @param device `Device`
 * @returns `string`
 */
export function isCompatibleToString(device: Device) {
  const stringArr: string[] = [];
  if (!device.is_compatible) {
    stringArr.push('Not Compatible');
  }
  if (device.needs_reboot) {
    stringArr.push('Needs Reboot');
  }
  const compatibilityChecks = Object.entries(device.compatibility_checks ?? {});
  if (!device.is_compatible && compatibilityChecks.length > 0) {
    compatibilityChecks.forEach(([key, value]) => {
      if (value) {
        stringArr.push(snakeToReadable(key));
      }
    });
  }
  return stringArr.join(', ');
}

/**
 * Use to format the `server_group_id` column into a string for a CSV
 * @param deviceGroupsById `DeviceGroupByIdMap`
 * @returns A function of the form `(row: Device) => string`
 */
export function makeServerGroupIdToString(
  deviceGroupsById: DeviceGroupByIdMap,
) {
  return (device: Device) =>
    (isNonNullable(device.server_group_id) &&
      deviceGroupsById[device.server_group_id] &&
      deviceGroupsById[device.server_group_id].name) ||
    'Default';
}

/**
 *
 * @param orgId The current `orgId`
 * @param selectedDevices The devices to export
 * @param tableHeaders The columns to export
 * @param deviceGroupsById A map `Server Group Id -> Device Group`
 * @returns a Promise that resolves when the csv has been downloaded
 */
export function exportDevicesToCSV(
  orgId: number,
  selectedDevices: readonly Device[],
  tableHeaders: readonly DataTableHeader<Device>[],
  deviceGroupsById: DeviceGroupByIdMap,
) {
  return generateAndDownloadCSV(
    selectedDevices,
    tableHeaders.map((header) => ({ name: header.text, id: header.value })),
    `ax-devices_org-${orgId}_${format(new Date(), DATE_FORMAT)}.csv`,
    {
      is_compatible: isCompatibleToString,
      server_group_id: makeServerGroupIdToString(deviceGroupsById),
      os_version: getOSNameVersion,
      status: deviceStatusText,
    },
  ).catch(showStandardCSVExportErrorNotification);
}

export function useGroupDevices(
  router: VueRouter,
  route: Route,
  orgId: Ref<OrgIdNumber>,
  groupId: Ref<number | undefined>,
) {
  const tableQueryString = shallowRef<string>();

  const queryString = computed(() => {
    const currentGroupId = groupId.value;
    const currentApiQuery = tableQueryString.value;
    return currentGroupId !== undefined && currentApiQuery !== undefined
      ? `${currentApiQuery}&groupId=${currentGroupId}`
      : undefined;
  });

  const {
    devices,
    totalDevices,
    getDevices: getPagedDevices,
  } = sharedUseDevices(queryString);

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-group-device-prefs',
    store: localStorage,
  };

  const { tableState } = DataTableState.synchronize(
    route,
    router,
    {
      ...baseDevicesQueryStateConfiguration(orgId),
      frompage: { defaultValue: undefined, excludeFromApiUrl: true },
    },
    {
      storageConfig,
      onBeforeApiUrlGeneration: (state) => {
        return { ...state, sort_by: [state.sort_by] };
      },
      callback: ({ apiQuery }) => {
        tableQueryString.value = apiQuery;
      },
    },
  );

  return { devices, totalDevices, tableState, getPagedDevices };
}

export function useDevicesForAssignmentToGroup(context: SetupContext) {
  const { deviceGroupsById, deviceGroups } = useDeviceGroups(context);
  const groupId = computed(() => Number(context.root.$route.params.groupId));
  const orgId = computed(() => Number(context.root.$route.query.o));
  const selectedDevices = shallowRef<Device[]>([]);
  const searchSelections = ref<Array<FilterOption | string>>([]);

  const queryString = shallowRef<string>();

  const { devices, totalDevices } = sharedUseDevices(queryString);

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-assign-devices-to-group-prefs',
    store: localStorage,
  };

  const allSearchSuggestions = ref<Array<FilterOption | string>>([
    'Recently Added',
    { text: 'Compliant', value: { [COMPLIANT]: 1 }, exclusionKey: COMPLIANT },
    {
      text: 'Non Compliant',
      value: { [COMPLIANT]: 0 },
      exclusionKey: COMPLIANT,
    },
    { text: 'Pending', value: { [PENDING]: 1 } },
    {
      text: 'Disconnected',
      value: { [CONNECTED]: 0 },
      exclusionKey: CONNECTED,
    },
    { text: 'Connected', value: { [CONNECTED]: 1 }, exclusionKey: CONNECTED },
    { text: 'Patch Status: Missing', value: { patchStatus: 'missing' } },
    'Windows',
    'Linux',
    'Mac',
    ...deviceGroups.value.map((group) => ({
      text: `Group: ${group.name || 'Default'}`,
      value: { groupId: group.id },
      exclusionKey: 'DeviceGroup',
    })),
  ] as Array<FilterOption | string>);

  const presetFilterQueryStateConfiguration = {
    [COMPLIANT]: { defaultValue: undefined },
    [PENDING]: { defaultValue: undefined },
    [CONNECTED]: { defaultValue: undefined },
    patchStatus: { defaultValue: undefined },
  };

  const { tableState, updateQueryState } = DataTableState.synchronize(
    context.root.$route,
    context.root.$router,
    {
      ...baseDevicesQueryStateConfiguration(orgId),
      ...presetFilterQueryStateConfiguration,
      filter: {
        defaultValue: [],
      },
    },
    {
      storageConfig,
      synchronizeApiOnly: true,
      onBeforeApiUrlGeneration: (state) => {
        const next = state;
        if (next.sort_by === 'os_version') {
          next.sort_by = 'os_name';
        }
        return { ...next, sort_by: [next.sort_by] };
      },
      callback: ({ apiQuery }) => {
        queryString.value = apiQuery;
      },
    },
  );

  const searchSuggestions = shallowRef<Array<FilterOption | string>>(
    allSearchSuggestions.value,
  );

  function assignDevices(): Promise<void> {
    return context.root.$store
      .dispatch(DEVICE_GROUPS_ASSIGN, {
        orgId: context.root.$route.query.o,
        deviceGroupId: groupId.value,
        deviceIds: selectedDevices.value.map(({ id }) => id),
      })
      .then(() => {
        context.emit('assign');
        Vue.notify({
          group: 'snackbar',
          text: 'Devices Successfully Assigned',
          type: '{ "card": "success" }',
        });
      })
      .catch((error) => {
        context.emit('close-modal');
        Vue.notify({
          group: 'snackbar',
          text: error,
          type: '{ "card": "error" }',
        });
      });
  }

  /**
   * When a user chooses a suggested filter, sometimes other filters need to be excluded since they are
   * mutually exclusive.  For example, choosing "Connected" should remove "Disconnected" as an option.
   */
  function excludeFilters(
    allOptions: Array<FilterOption | string>,
    currentSelections: Array<FilterOption | string>,
  ): Array<FilterOption | string> {
    return allOptions.filter((option) =>
      (option as FilterOption).exclusionKey
        ? !currentSelections.some(
            (selection) =>
              (selection as FilterOption).exclusionKey ===
              (option as FilterOption).exclusionKey,
          )
        : true,
    );
  }

  return {
    searchSelections,
    searchSuggestions,
    allSearchSuggestions,
    selectedDevices,
    tableState,
    updateQueryState,
    presetFilterQueryStateConfiguration,
    devices,
    deviceGroupsById,
    totalDevices,
    groupId,
    excludeFilters,
    assignDevices,
  };
}

export const deviceGroupHeaders: TableHeader[] = [
  {
    text: 'Device Name',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'display_name',
  },
  {
    text: 'Tags',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'tags',
  },
  {
    text: 'IP Address',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'ip_addrs_private',
  },
  {
    text: 'OS',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'os_family',
  },
  {
    text: 'OS Version',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'os_version',
  },
  {
    text: 'Scheduled Patches',
    class: 'tw-leading-none tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    width: '6.75rem',
    value: 'pending_patches',
  },
  // TODO: Add Status
];

export const addDevicesToGroupHeaders: TableHeader[] = [
  {
    text: 'OS',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'os_family',
  },
  {
    text: 'Device Name',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'display_name',
  },
  {
    text: 'Group',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'server_group_id',
  },
  {
    text: 'Tags',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'tags',
    align: 'center',
  },
  {
    text: 'IP Address',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'ip_addrs',
  },
  {
    text: 'OS Version',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'os_version',
  },
  {
    text: 'Status',
    class: 'tw-shitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'status',
  },
];
