import {
  computed,
  onMounted,
  ref,
  watch,
  SetupContext,
} from '@vue/composition-api';
import { AxiosResponse } from 'axios';
import { arrayToTree, flatDeep } from '@/utils/util';

import usePreferences from '@/compositions/usePreferences';
import { Device } from '@/models/devices';
import { DeviceGroup } from '@/models/device-group';
import {
  getDeviceGroup,
  createDeviceGroup,
  updateDeviceGroup,
  deleteDeviceGroup,
} from '@/services/device-groups.service';
import {
  DEVICE_GROUPS_IS_LOADING,
  DEVICE_GROUPS_ASSIGN,
  DEVICE_GROUPS_REQUEST,
} from '@/store/actions/device-groups';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateStorageConfig } from '@/utils/storage';
import { DeviceGroupPayload } from '@/types/device-group';
import { SelectItem, FilterOption } from '@/types/select-item';
import { TableHeader } from '@/types/table-options';
import { DataTableState } from '@/utils/data-table-state';

export type DeviceGroupByIdMap = { [key: number]: DeviceGroup };

export function useDeviceGroups(context: SetupContext) {
  const { currentOrganization } = usePreferences(context);
  const loading = computed<boolean>(
    () => context.root.$store.getters[DEVICE_GROUPS_IS_LOADING],
  );
  const deviceGroups = ref<DeviceGroup[]>([]);
  const deviceGroupsById = ref<DeviceGroupByIdMap>({});
  // TODO Remove Select and Options
  const deviceGroupsSelect = ref<SelectItem[]>([]);
  const deviceGroupsSearchOptions = ref<FilterOption[]>([]);

  const defaultGroup = computed(() =>
    deviceGroups.value.find(
      ({ id, parent_server_group_id }) => id === parent_server_group_id,
    ),
  );

  watch(
    currentOrganization,
    (org, prevOrg) => {
      if (org.id) {
        const isNewOrg = prevOrg && prevOrg.id && prevOrg.id !== org.id;
        if (context.root.$route.name === 'deviceDetails' && isNewOrg) {
          context.root.$router.replace({
            path: '/devices',
            query: { o: String(org.id) },
          });
        } else {
          getDeviceGroups(org.id);
        }
      }
    },
    {
      immediate: true,
    },
  );

  function getDeviceGroups(orgId: string | number) {
    context.root.$store
      .dispatch(DEVICE_GROUPS_REQUEST, orgId)
      .then((response) => {
        deviceGroups.value = response;
        deviceGroupsSelect.value = nestGroupsForSelect(response);
        deviceGroupsSearchOptions.value = mapSearchOption(response);
        deviceGroupsById.value = generateIdMap(deviceGroups.value);
      });
  }

  function generateIdMap(groups: DeviceGroup[]): DeviceGroupByIdMap {
    return groups.reduce(
      (acc, next) => ({ ...acc, [next.id!]: next }),
      {} as DeviceGroupByIdMap,
    );
  }

  function nestGroupsForSelect(groups: DeviceGroup[]) {
    const tree = arrayToTree(groups, 'parent_server_group_id');
    const grouped = flatDeep(tree, 'name');

    return grouped.map(({ ui_color, depth, ...group }) => ({
      text: group.name || 'Default',
      value: group.id,
      ui_color,
      depth,
    }));
  }

  function mapSearchOption(nextGroups: DeviceGroup[]): FilterOption[] {
    return nextGroups.map((group) => ({
      text: `Group - ${group.name || 'Default'}`,
      value: { key: 'groups', value: group.id || -1 },
    }));
  }

  // removes group from display/memory after having been deleted
  function removeGroupFromDisplay(groupId: number): void {
    deviceGroups.value = deviceGroups.value.filter(
      (group) => groupId !== group.id,
    );
  }

  return {
    defaultGroup,
    deviceGroups,
    deviceGroupsById,
    deviceGroupsSelect,
    deviceGroupsSearchOptions,
    loading,
    getDeviceGroups,
    removeGroupFromDisplay,
  };
}

// returns deviceGroup members that have the same ids passed in the groups param
export function getIncludedGroups(
  allGroups: DeviceGroup[],
  groups: number[] | undefined,
): DeviceGroup[] {
  return allGroups.filter(
    (group) =>
      group.id && groups && (groups as number[]).includes(group.id as number),
  );
}

// returns deviceGroup members that do not have ids found in the groups param
export function getExcludedGroups(
  allGroups: DeviceGroup[],
  groups: number[] | undefined,
): DeviceGroup[] {
  return groups
    ? allGroups.filter(
        (group) =>
          group.id && !(groups as number[]).includes(group.id as number),
      )
    : allGroups;
}

export function useGroupsTableState(context: SetupContext) {
  const orgId = computed(() => Number(context.root.$route.query.o));
  const UNUSED_FILTER = 'unused';
  const query = computed(() => context.root.$route.query);
  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-system-groups-prefs',
    store: localStorage,
  };

  const tableHeaders: TableHeader[] = [
    {
      text: 'Name',
      class: 'tw-whitespace-no-wrap',
      divider: true,
      value: 'name',
      filter: handleSearch,
    },
    {
      text: 'Scan Interval',
      class: 'tw-whitespace-no-wrap',
      divider: true,
      filterable: false,
      value: 'scan_interval',
    },
    {
      text: 'Devices',
      class: 'tw-whitespace-no-wrap',
      divider: true,
      filterable: false,
      value: 'server_count',
    },
    {
      text: 'Policies',
      class: 'tw-whitespace-no-wrap',
      divider: true,
      filterable: false,
      value: 'policy_count',
    },
    {
      text: 'Actions',
      align: 'center',
      class: 'tw-whitespace-no-wrap',
      divider: true,
      filterable: false,
      width: '8.25rem',
      value: 'actions',
    },
  ];

  const { tableState } = DataTableState.synchronize(
    context.root.$route,
    context.root.$router,
    {
      o: { defaultValue: orgId.value },
      page: { defaultValue: 1 },
      limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    },
    {
      storageConfig,
    },
  );

  const searchFilter = ref<string>('');
  const showOnlyUnusedFilter = ref<boolean>(false);

  onMounted(() => {
    showOnlyUnusedFilter.value = query.value.filter_by === UNUSED_FILTER;
    searchFilter.value = (query.value.search as string) || '';
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleSearch(value: any, search: string | null, item: any): boolean {
    const normalizedSearch = search ? search.toLowerCase() : '';

    if (
      showOnlyUnusedFilter.value &&
      item.server_count > 0 &&
      item.policies &&
      item.policies.length > 0
    ) {
      return false;
    }

    const name = item.name ? item.name.toLowerCase() : 'default';

    return name.indexOf(normalizedSearch) !== -1;
  }

  function syncUnusedFilter(showUnused) {
    const nextQuery = { ...query.value };
    if (showUnused) {
      context.root.$router.replace({
        query: { ...nextQuery, filter_by: UNUSED_FILTER },
      });
    } else {
      delete nextQuery.filter_by;

      context.root.$router.replace({ query: nextQuery });
    }
  }

  function syncSearch(value) {
    const nextQuery = { ...query.value };
    if (value) {
      context.root.$router.replace({ query: { ...nextQuery, search: value } });
    } else {
      delete nextQuery.search;

      context.root.$router.replace({ query: nextQuery });
    }
  }

  return {
    searchFilter,
    showOnlyUnusedFilter,
    tableHeaders,
    tableState,
    handleSearch,
    syncUnusedFilter,
    syncSearch,
  };
}

export function useDefaultGroupForOrg(context: SetupContext) {
  const defaultGroup = ref<DeviceGroup>();
  const orgId = computed(() => context.root.$route.query.o);

  function assignDefaultGroup(_groups: DeviceGroup[]) {
    defaultGroup.value = _groups.find(
      ({ id, parent_server_group_id }) => id === parent_server_group_id,
    );
  }

  watch(
    orgId,
    (nextOrgId) => {
      context.root.$store
        .dispatch(DEVICE_GROUPS_REQUEST, nextOrgId)
        .then(assignDefaultGroup);
    },
    { immediate: true },
  );

  return defaultGroup;
}

export function assignDeviceGroup(
  context: SetupContext,
  deviceGroupId: number,
  devices: Device[],
) {
  const { currentOrganization } = usePreferences(context);
  const deviceIds = devices.map(
    (device): number | undefined => device && device.id,
  );

  return context.root.$store.dispatch(DEVICE_GROUPS_ASSIGN, {
    orgId: currentOrganization.value.id,
    deviceGroupId,
    deviceIds,
  });
}

export function getGroup(
  orgId: string | number,
  groupId: string | number,
): Promise<AxiosResponse<DeviceGroup>> {
  return getDeviceGroup(orgId, groupId);
}

export function createGroup(
  orgId: string | number,
  payload: DeviceGroupPayload,
): Promise<DeviceGroup> {
  return createDeviceGroup(orgId, payload);
}

export function updateGroup(group: DeviceGroup): Promise<AxiosResponse> {
  return updateDeviceGroup(group);
}

export function removeGroup(group: DeviceGroup): Promise<AxiosResponse> {
  return deleteDeviceGroup(group);
}

export const groupsFooter = {
  'items-per-page-options': [10, 25, 50, 100, -1],
  'items-per-page-text': 'Groups per page:',
};
